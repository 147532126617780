<template>
  <SliderCarrousel
    :slides="slides"
    :totalSteps="totalSteps"
    localStorageKey="SliderCheckInCompleted"
    :callback="callback"
    :close="origin === 'ob-questionnaire' ? null : goBack"
  />
</template>
<script>
import { PNG, CheckIn } from '@seliaco/red-panda'
import { checkInSlider, checkInWelcome } from './configuration/configuration'

import SliderCarrousel from '@/components/misc/SliderCarrousel.vue'

export default {
  name: 'CheckInSlider',
  components: {
    SliderCarrousel
  },
  props: {
    origin: {
      type: String,
      default: 'Home'
    }
  },
  data () {
    return {
      slides: null,
      hasBenefit: this.origin === 'ob-questionnaire',
      totalSteps: this.origin === 'ob-questionnaire' ? 1 : 3,
      isDone: JSON.parse(localStorage.getItem('SliderCheckInCompleted')),
      counters: []
    }
  },
  mounted () {
    if (this.hasBenefit) {
      this.slides = checkInWelcome(this.$translations, PNG)
    } else {
      this.slides = checkInSlider(this.$translations, PNG)
    }
  },
  methods: {
    callback () {
      if (this.hasBenefit) {
        const body = {
          type: 'WELLNESS',
          always_create: true
        }
        CheckIn.startWellnessCheckIn(body).then((checkin) => {
          this.counters = {
            ...checkin,
            fromSignUp: true
          }

          this.$router.push({
            name: 'CheckInForm',
            query: {
              counterData: JSON.stringify(this.counters)
            }
          })
        })
      } else {
        this.$router.push({
          name: 'CheckIn',
          query: {
            type: 'WELLNESS'
          }
        })
      }
    },
    goBack () {
      this.$router.push({
        name: 'Home'
      })
    }
  }
}
</script>
