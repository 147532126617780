export const checkInSlider = ($translations, img) => ([
  {
    img: img.DudesStats,
    title: $translations['check-in'].slider.config[0].title,
    text: $translations['check-in'].slider.config[0].text,
    background: 'gradient',
    button: {
      text: $translations['check-in'].slider.continue,
      type: 'outline'
    }
  },
  {
    img: img.DudesResearch,
    title: $translations['check-in'].slider.config[1].title,
    text: $translations['check-in'].slider.config[1].text,
    background: 'gradient',
    button: {
      text: $translations['check-in'].slider.continue,
      type: 'outline'
    }
  },
  {
    img: img.DudesStudying,
    title: $translations['check-in'].slider.config[2].title,
    text: $translations['check-in'].slider.config[2].text,
    background: 'gradient-alt',
    button: {
      text: $translations['check-in'].slider.button,
      type: 'block'
    }
  }
])

export const checkInWelcome = ($translations, img) => ([
  {
    img: img.DudesResearch,
    title: $translations['check-in'].welcome.title,
    text: $translations['check-in'].welcome.description,
    background: 'gradient',
    button: {
      text: $translations['check-in'].welcome.action,
      type: 'block'
    }
  }
])
